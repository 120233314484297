.flex {
  display: flex;
}
.mainView {
  overflow: hidden;
  
  .MenuView {
    float: left;
    width: 256px;
    height: 100vh;
    background-color: #001529;
    position: relative;
    h1 {
      color: #fff;
      margin: 20px auto;
      text-align: center;
    }
    .LiView {
      // justify-content: center;
      align-items: center;
      justify-content: center;
    }
    .loginOutView {
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: 50px;
    }
  }
  .rightView {
    float: right;
    width: calc(100% - 256px);
    position: relative;
    height: 100vh;
    overflow-y: scroll;
  }
  
}
